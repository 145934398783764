import React from 'react'

import Container from '../components/Container'
import Header from '../components/Header'
import Footer from '../components/Footer'
import H1 from '../components/H1'
import P from '../components/P'
import Layout from '../components/layout'

const Kontakt = ({ location }) => (
  <Layout>
    <Header pathname={location.pathname} />
      <Container>
        <H1>Kontakt</H1>
        <P>CSU Ortsverband Kastl<br />
        Johann Walter<br />
        Hauptstraße 19<br />
        95506 Kastl<br />
        <br />
        info@kastl-csu.de</P>
    </Container>
    <Footer />
  </Layout>
)

export default Kontakt